import { Product } from '@/types/Product';

export interface iCartProduct extends Product {
    amount: number;
}

export class CartProduct implements iCartProduct {
    public name: string;
    public catelogId: string;
    public categoryName: string;
    public specs: string;
    public details: string;
    public description: string;
    public price: number | string;
    public image: string;
    public amount: number;
    public hovered = false;


    constructor(p: Product, amount = 0) {
        this.name = p.name;
        this.catelogId = p.catelogId;
        this.categoryName = p.categoryName;
        this.specs = p.specs;
        this.details = p.details;
        this.description = p.description;
        this.price = p.price;
        this.image = p.image;
        this.amount = amount;
    }
}