import { defineStore, StoreDefinition } from 'pinia'
import { Product } from '@/types/Product';
import { ProductState } from '@/types/ProductState';
import { PersistedStateOptions } from 'pinia-plugin-persistedstate';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import useHooks from '@croffasia/vue3-custom-hooks';
import { CartProduct } from '@/types/CartProduct';


const productList: Product[] = [
    {
        name: "General surgical Set",
        catelogId: "08010",
        categoryName: "Surgical Sets",
        specs: "",
        details: "",
        description: "<table style=\"min-width: 300px!important;\"><thead><tr><th>Amount</th><th>Name</th><th>Details</th></tr></thead><tbody><tr><td>6</td><td>Towel Clamps</td><td>100mm</td></tr><tr><td>1</td><td>Backhaus Towel Forceps</td><td>140mm</td></tr><tr><td>4</td><td>Rampley’s Sponge-holders</td><td>180mm</td></tr><tr><td>1</td><td>Adson Toothed Dissecting Forceps</td><td>120mm</td></tr><tr><td>1</td><td>Plain Dissecting Forceps</td><td>140mm</td></tr><tr><td>2</td><td>Scalpel Handle</td><td>Nr.4, Nr5</td></tr><tr><td>2</td><td>Allis Tissue Forceps</td><td>5x6 teeth</td></tr><tr><td>2</td><td>Crile-Wood Needle-Holder</td><td>Length 12cm and 16cm</td></tr><tr><td>2</td><td>Lane’s Tissue Forceps</td><td>150mm, 1 into 2 teeth</td></tr><tr><td>3</td><td>Spencer Wells Haemostats</td><td>Curved: 130mm, 150mm, 175mm</td></tr><tr><td>3</td><td>Spencer Wells Haemostats</td><td>Straight: 130mm, 150mm, 175mm</td></tr><tr><td>6</td><td>Halstead’s Haemostats</td><td>Curved: 3x100mm, 3x125mm</td></tr><tr><td>6</td><td>Halstead’s Haemostats</td><td>Straight: 3x100mm, 3x125mm</td></tr><tr><td>2</td><td>Kocher’s Artery Forceps</td><td>140mm</td></tr><tr><td>2</td><td>Czemy’s Retractors</td><td>200mm</td></tr><tr><td>2</td><td>Morris’ Retractors</td><td>245mm, 70x50mm, 50x50mm</td></tr><tr><td>1</td><td>Poole’s Sucker Tube</td><td>D:10mm, length 220mm</td></tr><tr><td>1</td><td>Gallipot</td><td>D:128mm</td></tr></tbody></table>",
        price: "Request quote",
        image: "s1.jpg"
    },
    {
        name: "Delivery surgical set",
        catelogId: "08011",
        categoryName: "Surgical Sets",
        specs: "",
        details: "",
        description: "<table style=\"min-width: 300px!important;\"><thead><tr><th>Amount</th><th>Name</th><th>Details</th></tr></thead><tbody><tr><td>2</td><td>Kidney Dish</td><td>Stainless steel</td></tr><tr><td>2</td><td>Straight Artery Forceps - Crile</td><td>140mm, 145mm</td></tr><tr><td>1</td><td>Gallipot</td><td>D:128mm</td></tr><tr><td>2</td><td>Dissecting Forceps</td><td>Toothed: 120mm, Plain:140mm</td></tr><tr><td>1</td><td>Bowl</td><td>D140*L55mm</td></tr><tr><td>1</td><td>Rampley Sponge Holding Forceps</td><td>230mm</td></tr><tr><td>1</td><td>Needle Holder</td><td>150mm</td></tr><tr><td>6</td><td>Mayo Scissors</td><td>3xcurved: 140mm, 3xstraight: 140mm</td></tr><tr><td>3</td><td>Cusco Speculum</td><td>27x125mm</td></tr></tbody></table>",
        price: "Request quote",
        image: "s2.jpg"
    },
    {
        name: "Examination surgical set",
        catelogId: "08012",
        categoryName: "Surgical Sets",
        specs: "",
        details: "",
        description: "<table style=\"min-width: 300px!important;\"><thead><tr><th>Amount</th><th>Name</th><th>Details</th></tr></thead><tbody><tr><td>1</td><td>Basket</td><td>250x150x50 mm</td></tr><tr><td>4</td><td>Backhaus Towel Forceps</td><td>130mm</td></tr><tr><td>2</td><td>Forceps Hemostatic Chaput</td><td>2x3 teeth, 130mm</td></tr><tr><td>6</td><td>Forceps Hemostatic Halsted-Mosquito</td><td>Curved</td></tr><tr><td>1</td><td>Forceps Hemostatic Péan</td><td>Straight, 140mm</td></tr><tr><td>1</td><td>Forceps Dressing Spring Type</td><td>140mm</td></tr><tr><td>1</td><td>Forceps Hemostatic Collin</td><td>16mm, oval jaws</td></tr><tr><td>1</td><td>Forceps Tissue Spring-type</td><td>1x2 teeth, 145 mm</td></tr><tr><td>1</td><td>Gallipot</td><td>100ml, without lid, stainless steel</td></tr><tr><td>1</td><td>Needle Holder Baby Crile-Wood</td><td>150mm, delicate</td></tr><tr><td>1</td><td>Needle Holder Mayo-Hegar</td><td>straight, narrow jaw</td></tr><tr><td>1</td><td>Probe</td><td>round point &amp; tongue‐tie, 145 mm</td></tr><tr><td>1</td><td>Retractor Farabeuf</td><td>150mm - set of 2 sizes</td></tr><tr><td>1</td><td>Retractor Farabeuf</td><td>120mm ‐ set of 2 sizes</td></tr><tr><td>1</td><td>Scalpel Handle</td><td>No. 4</td></tr><tr><td>1</td><td>Scissors Dissecting Metzenbaum-Nelson</td><td>Curved ‐ 18 cm, b/b</td></tr><tr><td>1</td><td>Scissors Dissecting Mayo</td><td>Curved, 14.5 cm, b/b</td></tr></tbody></table>",
        price: "Request quote",
        image: "s3.jpg"
    },
    {
        name: "Family planning surgical set",
        catelogId: "08013",
        categoryName: "Surgical Sets",
        specs: "",
        details: "",
        description: "<table style=\"min-width: 300px!important;\"><thead><tr><th>Amount</th><th>Name</th><th>Details</th></tr></thead><tbody><tr><td>2</td><td>Cup</td><td>100ml, without lid, stainless steel</td></tr><tr><td>1</td><td>Forceps Sponge Forester</td><td>Straight 140mm</td></tr><tr><td>1</td><td>Forceps Dressing</td><td>Standard pattern 127mm</td></tr><tr><td>1</td><td>Forceps Tissue</td><td>Delicate pattern 140mm</td></tr><tr><td>1</td><td>Forceps Artery Kelly</td><td>Straight 144mm</td></tr><tr><td>1</td><td>Forceps Intestinal Allis</td><td>Delicate, 5x6 Teeth, 152mm</td></tr><tr><td>1</td><td>Forceps Intestinal Baby Babcock</td><td>140mm</td></tr><tr><td>1</td><td>Needle Holder Mayo-Hegar</td><td>178mm</td></tr><tr><td>1</td><td>Richardson-Eastman retractor</td><td>Small, two piece set</td></tr><tr><td>1</td><td>Scissors Tonsil Metzenbaum</td><td>Curved, 178mm</td></tr><tr><td>1</td><td>Scissors Operating Mayo</td><td>Curved, 171mm</td></tr><tr><td>1</td><td>Scalpel Handle</td><td>#3</td></tr><tr><td>1</td><td>Hook Tubal</td><td>Ramathibodi</td></tr><tr><td>1</td><td>Forceps Sponge Foerster</td><td>Curved, 241mm</td></tr><tr><td>1</td><td>Jackson Vaginal Retractor</td><td>38x76mm</td></tr><tr><td>1</td><td>Forceps Schroeder-Braun Uterine Tenaculum</td><td>248mm</td></tr><tr><td>1</td><td>Elevator Uterine</td><td>Ramathibodi</td></tr></tbody></table>",
        price: "Request quote",
        image: "s4.jpg"
    },
    {
        name: "Mayo-Hegar Needle holder",
        catelogId: "01001",
        categoryName: "Needleholders",
        specs: "Straight, Fine Serrated",
        details: "12cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 50,
        image: "14b.jpg"
    },
    {
        name: "Mayo-Hegar Needle holder",
        catelogId: "01002",
        categoryName: "Needleholders",
        specs: "Straight, Fine Serrated",
        details: "13cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 50,
        image: "14b.jpg"
    },
    {
        name: "Mayo-Hegar Needle holder",
        catelogId: "01003",
        categoryName: "Needleholders",
        specs: "Straight, Fine Serrated",
        details: "15cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 50,
        image: "14b.jpg"
    },
    {
        name: "Mayo-Hegar Needle holder",
        catelogId: "01004",
        categoryName: "Needleholders",
        specs: "Straight, Fine Serrated",
        details: "16cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 50,
        image: "14b.jpg"
    },
    {
        name: "Mayo-Hegar Needle holder",
        catelogId: "01005",
        categoryName: "Needleholders",
        specs: "Straight, Fine Serrated",
        details: "18cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 50,
        image: "14b.jpg"
    },
    {
        name: "Mayo-Hegar Needle holder",
        catelogId: "01006",
        categoryName: "Needleholders",
        specs: "Straight, Fine Serrated",
        details: "20cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 50,
        image: "14b.jpg"
    },
    {
        name: "Mayo-Hegar Needle holder",
        catelogId: "01007",
        categoryName: "Needleholders",
        specs: "Straight, Fine Serrated",
        details: "23cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 50,
        image: "14b.jpg"
    },
    {
        name: "Laprascopic Needle Holder",
        catelogId: "01011",
        categoryName: "Needleholders",
        specs: "Straight",
        details: "5mm,33cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 60,
        image: "29.jpg"
    },
    {
        name: "Adson Tweezer",
        catelogId: "02001",
        categoryName: "Tweezers",
        specs: "Without Hook, Fine Serrated",
        details: "10cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 24.5,
        image: "17c.jpg"
    },
    {
        name: "Adson Tweezer",
        catelogId: "02002",
        categoryName: "Tweezers",
        specs: "Without Hook, Fine Serrated",
        details: "12cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 24.5,
        image: "17c.jpg"
    },
    {
        name: "Adson Tweezer",
        catelogId: "02003",
        categoryName: "Tweezers",
        specs: "Without Hook, Fine Serrated",
        details: "16cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 24.5,
        image: "17c.jpg"
    },
    {
        name: "Adson Tweezer",
        catelogId: "02011",
        categoryName: "Tweezers",
        specs: "With Hook, Fine serrated",
        details: "10cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 20,
        image: "15c.jpg"
    },
    {
        name: "Adson Tweezer",
        catelogId: "02012",
        categoryName: "Tweezers",
        specs: "With Hook, Fine serrated",
        details: "12cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 20,
        image: "15c.jpg"
    },
    {
        name: "Adson Tweezer",
        catelogId: "02013",
        categoryName: "Tweezers",
        specs: "With Hook, Fine serrated",
        details: "16cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 20,
        image: "15c.jpg"
    },
    {
        name: "Iris Scissors",
        catelogId: "03001",
        categoryName: "Scissors",
        specs: "Straight, Sharp",
        details: "12cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 5,
        image: "22c.jpg"
    },
    {
        name: "Iris Scissors",
        catelogId: "03002",
        categoryName: "Scissors",
        specs: "Straight, Sharp",
        details: "13cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 5,
        image: "22c.jpg"
    },
    {
        name: "Iris Scissors",
        catelogId: "03003",
        categoryName: "Scissors",
        specs: "Straight, Sharp",
        details: "14cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 5,
        image: "22c.jpg"
    },
    {
        name: "General Purpose Scissors",
        catelogId: "03011",
        categoryName: "Scissors",
        specs: "Straight, Blunt",
        details: "12cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 4,
        image: "21b.jpg"
    },
    {
        name: "General Purpose Scissors",
        catelogId: "03012",
        categoryName: "Scissors",
        specs: "Straight, Blunt",
        details: "13cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 4,
        image: "21b.jpg"
    },
    {
        name: "General Purpose Scissors",
        catelogId: "03013",
        categoryName: "Scissors",
        specs: "Straight, Blunt",
        details: "15cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 4,
        image: "21b.jpg"
    },
    {
        name: "Metzenbaum Scissors",
        catelogId: "03021",
        categoryName: "Scissors",
        specs: "Straight, Blunt",
        details: "13cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 40,
        image: "19c.jpg"
    },
    {
        name: "Metzenbaum Scissors",
        catelogId: "03022",
        categoryName: "Scissors",
        specs: "Straight, Blunt",
        details: "16cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 40,
        image: "19c.jpg"
    },
    {
        name: "Metzenbaum Scissors",
        catelogId: "03023",
        categoryName: "Scissors",
        specs: "Straight, Blunt",
        details: "18cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 40,
        image: "19c.jpg"
    },
    {
        name: "Metzenbaum Scissors",
        catelogId: "03031",
        categoryName: "Scissors",
        specs: "Curved, Blunt",
        details: "13cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 65,
        image: "20b.jpg"
    },
    {
        name: "Metzenbaum Scissors",
        catelogId: "02032",
        categoryName: "Scissors",
        specs: "Curved, Blunt",
        details: "16cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 65,
        image: "20b.jpg"
    },
    {
        name: "Metzenbaum Scissors",
        catelogId: "03033",
        categoryName: "Scissors",
        specs: "Curved, Blunt",
        details: "18cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 65,
        image: "20b.jpg"
    },
    {
        name: "Weitlaner Retractor",
        catelogId: "04001",
        categoryName: "Retractor",
        specs: "Self-retaining, Sharp",
        details: "5cm spread",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 205,
        image: "7c.jpg"
    },
    {
        name: "Senn-Miller Retractor",
        catelogId: "04011",
        categoryName: "Retractor",
        specs: "3-Prong, Sharp",
        details: "12cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 34.99,
        image: "10d.jpg"
    },
    {
        name: "Stevenson Retractor",
        catelogId: "04021",
        categoryName: "Retractor",
        specs: "2x4 Prong, Sharp",
        details: "22mm spread",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 9,
        image: "9d.jpg"
    },
    {
        name: "Mueller Retractor",
        catelogId: "04031",
        categoryName: "Retractor",
        specs: "2x3 Prong, Sharp Self-Retaining",
        details: "26mm spread",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 105,
        image: "8c.jpg"
    },
    {
        name: "Hemostatic Forceps",
        catelogId: "05001",
        categoryName: "Forceps",
        specs: "Curved",
        details: "14cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 38,
        image: "24c.jpg"
    },
    {
        name: "Hemostatic Forceps",
        catelogId: "05002",
        categoryName: "Forceps",
        specs: "Curved",
        details: "15,5cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 38,
        image: "24c.jpg"
    },
    {
        name: "Hemostatic Forceps",
        catelogId: "05011",
        categoryName: "Forceps",
        specs: "Straight",
        details: "14cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 7,
        image: "25b.jpg"
    },
    {
        name: "Hemostatic Forceps",
        catelogId: "05012",
        categoryName: "Forceps",
        specs: "Straight",
        details: "15,5cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 7,
        image: "25b.jpg"
    },
    {
        name: "Artery Forceps",
        catelogId: "05021",
        categoryName: "Forceps",
        specs: "Curved",
        details: "20cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 27,
        image: "23b.jpg"
    },
    {
        name: "Tubing clamp",
        catelogId: "05031",
        categoryName: "Forceps",
        specs: "Straight, Safety guard",
        details: "20cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 35,
        image: "26b.jpg"
    },
    {
        name: "Kerrison Mastoid Rongeur",
        catelogId: "05041",
        categoryName: "Forceps",
        specs: "45 Degree",
        details: "18cm shaft,2mm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 75,
        image: "30d.jpg"
    },
    {
        name: "Kerrison Mastoid Rongeur",
        catelogId: "06042",
        categoryName: "Towel Clamps",
        specs: "45 Degree",
        details: "20cm shaft,4mm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 75,
        image: "30d.jpg"
    },
    {
        name: "Struempel Ear Forceps",
        catelogId: "05051",
        categoryName: "Forceps",
        specs: "Oval Spoon shaped Jaws",
        details: "9cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 145,
        image: "28c.jpg"
    },
    {
        name: "Towel Clamp",
        catelogId: "06001",
        categoryName: "Towel Clamps",
        specs: "Self-Retaining",
        details: "10cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 64,
        image: "6f.jpg"
    },
    {
        name: "Towel Clamp",
        catelogId: "06002",
        categoryName: "Towel Clamps",
        specs: "Self-Retaining",
        details: "11cm",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus non quam at urna sollicitudin mollis. Ut quis mi augue. Morbi pretium lobortis nisl, eu auctor orci iaculis ac. Sed porttitor tincidunt ultricies. Etiam quis turpis efficitur nulla consectetur posuere. Donec pulvinar ligula in leo bibendum ullamcorper. Praesent a blandit quam, eget tincidunt erat. Nulla facilisi. Curabitur nulla nisi, commodo vehicula nunc ac, lobortis laoreet arcu. Cras pretium, odio ut condimentum congue, enim felis blandit tellus, non pulvinar eros ipsum bibendum magna. Duis ornare accumsan felis ac pretium.",
        price: 64,
        image: "6f.jpg"
    }
]

const PRODUCTADDED = 'ProductAdded';
const hooks = useHooks(PRODUCTADDED);

type ProductStore = StoreDefinition<string, ProductState, PersistedStateOptions, {
    filterProducts(input: string): void;
    addToCart(product: Product): void;
}>

export const useProductStore = defineStore('products', {
    state: () => ({
        products: productList,
        filteredProducts: [...productList],
        sortFunc: () => { true },
        lastSearch: "",
        cart: {
            totalProducts: 0,
            addedProducts: <CartProduct[]>[],
            onProductAdded: hooks.makeHook(PRODUCTADDED)
        }
    }),
    getters: {
        cartProductCount(state) {
            return state.cart.addedProducts.reduce(function (prev, cur) {
                return prev + cur.amount;
            }, 0);
        },
        // productsCheaperThan(state) {
        //     return (price: number) => (
        //         state.products.filter(product =>
        //             product.price < price
        //         )
        //     )
        // }
    },
    actions: {
        sortProductsNameAsc(filterList: Product[] | null = null) {
            this.sortFunc = this.sortProductsNameAsc;
            if (filterList === null) {
                filterList = this.filteredProducts;
            }
            this.filteredProducts = this.filteredProducts.sort(
                (p1, p2) => (p1.name + p1.details).localeCompare(p2.name + p2.details));
        },
        sortProductsNameDesc(filterList: Product[] | null = null) {
            this.sortFunc = this.sortProductsNameDesc;
            if (filterList === null) {
                filterList = this.filteredProducts;
            }
            this.sortProductsNameAsc();
            this.filteredProducts.reverse();
        },
        sortProductsPriceAsc(filterList: Product[] | null = null, desc = false) {
            this.sortFunc = this.sortProductsPriceAsc;
            if (filterList === null) {
                filterList = this.filteredProducts;
            }
            this.filteredProducts = this.filteredProducts.sort((p1, p2) => {
                if (typeof (p1.price) === 'string' && typeof (p2.price) === 'number') {
                    return -1;
                }
                if (typeof (p1.price) === 'number' && typeof (p2.price) === 'string') {
                    return 1;
                }
                if (typeof (p1.price) === 'string' && typeof (p2.price) === 'string') {
                    return 0;
                }
                else if (p1.price < p2.price) {
                    return (desc) ? 1 : -1;
                }
                else if (p1.price > p2.price) {
                    return (desc) ? -1 : 1;
                }
                return 0;
            });
        },
        sortProductsPriceDesc(filterList: Product[] | null = null) {
            this.sortFunc = this.sortProductsPriceDesc;
            if (filterList === null) {
                filterList = this.filteredProducts;
            }
            this.sortProductsPriceAsc(filterList, true);
        },
        sortProductsDefault() {
            this.sortFunc = () => { true };
            this.filteredProducts = [... this.products];
            this.filterProducts(this.lastSearch);
        },
        filterProducts(input: string) {
            let filterList = this.filteredProducts;
            if (this.lastSearch > input) {
                filterList = [... this.products];
            }
            this.filteredProducts = filterList.filter((p) =>
                ('#' + p.catelogId.toLowerCase()).includes(input.toLowerCase()) ||
                p.name.toLowerCase().includes(input.toLowerCase()) ||
                p.specs.toLowerCase().includes(input.toLowerCase()) ||
                p.details.toLowerCase().includes(input.toLowerCase()) ||
                ('$ ' + p.price.toString().toLowerCase()).includes(input.toLowerCase()) ||
                ('$' + p.price.toString().toLowerCase()).includes(input.toLowerCase())
            );
            this.sortFunc();
            this.lastSearch = input;
        },
        addToCart(product: Product) {
            const foundProduct = this.cart.addedProducts.find(p => p.catelogId == product.catelogId);
            if (typeof (foundProduct) === 'undefined') {
                this.cart.addedProducts.push(new CartProduct(product, 1))
            }
            else {
                foundProduct.amount += 1;
            }
            this.cart.totalProducts += 1;
        }
    },
    persist: {
        storage: sessionStorage,
        paths: ['cart.addedProducts'],
    },
});

// export const useProductStore = defineStore('products', {
//     state: () => ({
//         products: productList,
//         filteredProducts: productList,
//         cart: new CartManager(hooks.makeHook(PRODUCTADDED))
//     }),
//     getters: {
//         // cartProductCount(state: ProductState) {
//         //     let length = 0;
//         //     state.cart.addedProducts.forEach((p) => {
//         //         length += p.amount;
//         //     })
//         //     return length;
//         // },
//         // productsCheaperThan(state) {
//         //     return (price: number) => (
//         //         state.products.filter(product =>
//         //             product.price < price
//         //         )
//         //     )
//         // }
//         // cartProductCount() {
//         //     let length = 0;
//         //     this.cart.addedProducts.forEach((p) => {
//         //         length = length + p.amount;
//         //     })
//         //     return length;
//         // },
//     },
//     actions: {
//         filterProducts(input: string) {
//             this.filteredProducts = this.products.filter((p) =>
//                 p.title.toLowerCase().includes(input.toLowerCase())
//             );
//         },
//         addToCart(product: Product) {
//             this.cart.AddToCart(product);
//         }
//     },
//     persist: {
//         storage: sessionStorage,
//         paths: ['cart.addedProducts'],
//     },
// });
